<i18n>
{
  "en": {
    "login": "Login",
    "log-in": "Log in",
    "existing-users": "For existing LiveCall Bot users, please log in with your LINE WORKS Group name.",
    "group-name": "LINE WORKS Group name",
    "group-name-hint": "The part after @ in your user ID",
    "new-users": "New LiveCall Bot users can register here.",
    "setup": "Setup",
    "agree-to-tos": "I agree to the {0}.",
    "terms-of-service": "Terms of Service",
    "set-up": "Set up",
    "group-name-not-registered": "The entered LINE WORKS Group name is not yet registered at LiveCall for LINE WORKS"
  },
  "ja": {
    "login": "ログイン",
    "log-in": "ログイン",
    "existing-users": "既に、LiveCall for LINE WORKSをご利用頂いている方は、ワークスグループ名を入力してログインしてください。",
    "group-name": "ワークスグループ名",
    "group-name-hint": "LINE WORKSユーザーIDの「@」以降の部分となります",
    "new-users": "LiveCall for LINE WORKSのご登録はこちら。",
    "setup": "新規登録",
    "agree-to-tos": "{0}に同意する。",
    "terms-of-service": "ご利用条件",
    "set-up": "登録",
    "group-name-not-registered": "入力されたワークスグループは、LiveCall for LINE WORKSに未登録です"
  }
}
</i18n>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="0">
        <v-card elevation="4" :loading="working">
          <v-form :action="loginUrl" method="get" @submit.passive="login">
            <input type="hidden" name="next" :value="nextUrl">
            <v-card-title>{{ $t('login') }}</v-card-title>
            <v-card-subtitle>{{ $t('existing-users') }}</v-card-subtitle>
            <v-card-text class="pt-4 pb-0">
                <v-text-field outlined
                              :label="$t('group-name')"
                              placeholder="works-123456"
                              :hint="$t('group-name-hint')"
                              prefix="@"
                              v-model="groupId"
                              :disabled="working"
                              :error-messages="groupError"
                              @change="groupError = null">
                </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" type="submit" :disabled="!groupId || working">
                {{ $t('log-in') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="0">
        <v-card elevation="4">
          <v-card-title>{{ $t('setup') }}</v-card-title>
          <v-card-subtitle>{{ $t('new-users') }}</v-card-subtitle>
          <v-card-text>
            <v-checkbox hide-details v-model="tosAgreed">
              <template v-slot:label>
                <i18n path="agree-to-tos" tag="span">
                  <a href="https://livecall.jp/tos" target="_blank" @click.stop>{{ $t('terms-of-service') }}</a>
                </i18n>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" to="/setup/1" :disabled="!tosAgreed">{{ $t('set-up') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const localStorageKey = 'lineworksGroupId';

export default {
  name: 'Login',
  data: () => ({
    groupId: null,
    loginError: false,
    working: false,
    tosAgreed: false,
    groupError: null
  }),
  props: {
    samlToken: {
      type: String,
      required: false
    },
    error: {
      type: String,
      required: false
    }
  },
  computed: {
    loginUrl() {
      return `${this.$api.base}/saml/${this.groupId}`;
    },
    nextUrl() {
      const url = new URL(window.location);
      url.pathname = '/';
      return url.toString();
    }
  },
  created() {
    this.groupId = window.localStorage.getItem(localStorageKey);

    if (this.samlToken) {
      this.processLogin();
    }

    if (this.error === 'group') {
      this.groupError = this.$t('group-name-not-registered');
    }
  },
  methods: {
    login() {
      this.working = true;
      window.localStorage.setItem(localStorageKey, this.groupId);
    },
    async processLogin() {
      this.working = true;
      this.$api.setToken(this.samlToken);
      /** @type {Response} */
      const response = await this.$api.fetch('/auth/saml-token', { method: 'POST' });

      if (!response.ok) {
        this.loginError = true;
      } else {
        const tokens = await response.json();
        this.$api.setToken(tokens['access_token']);
        await this.$router.push({ name: 'Dashboard' });
      }

      this.working = false;
    }
  }
}
</script>
